import styled from 'styled-components'
import { devices } from '../../config/devices'

export const Title = styled.span`
  color: ${props => (props.color ? props.color : props.theme.font.simple)};
  font-size: ${props => (props.size ? props.size : '48px')};
  font-weight: ${props => (props.weight ? props.weight : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  line-height: ${props => (props.size ? props.size + 2 : '50px')};
  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : 'none'};
  text-transform: ${props =>
    props.textTransform ? props.textTransform : 'none'};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : 0)};
  @media ${devices.mobileL} {
    font-size: 8vw;
    line-height: 8.5vw;
  }
  @media ${devices.mobileS} {
    font-size: 4vw;
    line-height: 4.5vw;
  }
`

export const SubTitle = styled.span`
  color: ${props => (props.color ? props.color : props.theme.font.simple)};
  font-size: ${props => (props.size ? props.size : '24px')};
  font-weight: ${props => (props.weight ? props.weight : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  line-height: ${props => (props.size ? props.size + 2 : '26px')};
  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : 'none'};
  text-transform: ${props =>
    props.textTransform ? props.textTransform : 'none'};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : 0)};
  @media ${devices.mobileL} {
    font-size: 4vw;
    line-height: 4.5vw;
  }
  @media ${devices.mobileS} {
    font-size: 3vw;
    line-height: 3.5vw;
  }
`
