import React, { useEffect } from 'react'
import styled from 'styled-components'
import Header from '../components/header'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import HelmetConf from '../components/helmetConf'

import Question from '../components/question'

const Support = () => {
  const { t, i18n } = useTranslation()
  const questions = t('questions', { returnObjects: true })
  let { lang } = useParams()
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])
  return (
    <>
      <HelmetConf title='Muslimin Support' page='support'></HelmetConf>
      <SupportContainer>
        <QuestionContainer>
          {questions.map((question, index) => (
            <Question
              key={index}
              question={question.question}
              answer={question.answer}
            ></Question>
          ))}
        </QuestionContainer>
      </SupportContainer>
    </>
  )
}

const SupportContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const QuestionContainer = styled.div`
  width: 800px;
  padding: 12px;
`

export default Support
// export default Home
