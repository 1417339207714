import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PrimaryButton = ({ label, onClick }) => {
  return <Button onClick={onClick}>{label}</Button>
}

const Button = styled.button`
  cursor: pointer;
  border: none;
  padding: 12px;
  color: ${props => props.theme.font.button};
  background-color: ${props => props.theme.general.button};
  font-size: 18px;
  border-radius: 4px;
`

PrimaryButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default PrimaryButton
