import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Title, SubTitle } from '../texts'
import { motion } from 'framer-motion'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import iphone from '../../assets/iphone.png'
import prayer from '../../assets/prayer.jpeg'
import audioPlayer from '../../assets/audioPlayer.png'
import dua from '../../assets/dua.png'
import learning from '../../assets/learning.png'
import read from '../../assets/read.png'

import { devices } from '../../config/devices'

const carouselContent = [
  {
    image: prayer,
    title: 'carousel.prayer',
    subtitle: 'carousel.prayerSubtitle'
  },
  {
    image: learning,

    title: 'carousel.learning',
    subtitle: 'carousel.learningSubtitle'
  },
  {
    image: read,

    title: 'carousel.read',
    subtitle: 'carousel.readSubtitle'
  },
  {
    image: dua,

    title: 'carousel.doua',

    subtitle: 'carousel.douaSubtitle'
  },
  {
    image: audioPlayer,

    title: 'carousel.audioPlayer',
    subtitle: 'carousel.audioPlayerSubtitle'
  }
]

const Carousel = () => {
  const { t } = useTranslation()
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [content, setContent] = useState(carouselContent[carouselIndex])
  useEffect(() => {
    if (carouselContent[carouselIndex]) {
      setContent(carouselContent[carouselIndex])
    }
  }, [carouselIndex])

  const handlePrevious = async () => {
    if (carouselContent[carouselIndex - 1]) {
      setCarouselIndex(carouselIndex - 1)
    }
  }

  const handleNext = async () => {
    if (carouselContent[carouselIndex + 1]) {
      setCarouselIndex(carouselIndex + 1)
    }
  }

  return (
    <CarouselContainer>
      <TitleContainer>
        <Title textAlign='center' weight='bold'>
          {t(content.title)}
        </Title>
      </TitleContainer>
      <SubTitleContainer>
        <SubTitle textAlign='center' weight='500'>
          {t(content.subtitle)}
        </SubTitle>
      </SubTitleContainer>
      <PhoneAndButtonContainer>
        <ButtonLeftContainer>
          {carouselContent[carouselIndex - 1] ? (
            <FaChevronLeft
              size={30}
              onClick={handlePrevious}
              color='#ffffff'
            ></FaChevronLeft>
          ) : null}
        </ButtonLeftContainer>
        <PhoneContainer>
          <PhoneBezels
            variants={variants}
            initial='initial'
            animate='end'
            src={iphone}
          ></PhoneBezels>
          <PhoneContent
            variants={variants}
            initial='initial'
            animate='end'
            src={content.image}
          ></PhoneContent>
        </PhoneContainer>
        <ButtonRightContainer>
          {carouselContent[carouselIndex + 1] ? (
            <FaChevronRight
              size={30}
              color='#ffffff'
              onClick={handleNext}
            ></FaChevronRight>
          ) : null}
        </ButtonRightContainer>

        <ContentContainer>
          <PaginationContainer></PaginationContainer>
        </ContentContainer>
      </PhoneAndButtonContainer>
    </CarouselContainer>
  )
}

const variants = {
  initial: { y: 50, opacity: 0, transition: { duration: 1 } },
  end: { y: 0, opacity: 1, transition: { duration: 1 } }
}

const ButtonRightContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${devices.mobileL} {
    z-index: 4;
    width: 40px;
    height: 40px;
  }
`

const ButtonLeftContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${devices.mobileL} {
    z-index: 4;
    width: 40px;
    height: 40px;
  }
`

const PhoneAndButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PaginationContainer = styled.div``

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
`
const SubTitleContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 12px;
`

const PhoneContainer = styled.div`
  /* padding: 12px; */
`

const PhoneBezels = styled(motion.img)`
  width: 240px;
  position: absolute;
  z-index: 3;
  @media ${devices.mobileL} {
    z-index: 4;
    width: 240px;
  }
  @media ${devices.mobileS} {
    z-index: 4;
    width: 210px;
  }
`
const PhoneContent = styled(motion.img)`
  width: 203px;
  padding: 19px;
  height: 437px;
  border-radius: 30px;
  @media ${devices.mobileL} {
    width: 203px;
    padding: 19px;
    height: 437px;
    border-radius: 30px;
  }
  @media ${devices.mobileS} {
    width: 179px;
    padding: 15px;
    height: 386px;
    border-radius: 30px;
  }
`

const ContentContainer = styled.div`
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
`

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.general.carousel};
  min-width: 200px;
  margin-top: ;
`

export default Carousel
