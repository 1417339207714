import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Logo = ({ image }) => {
  return <StyledImage src={image}></StyledImage>
}

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`

Logo.propTypes = {
  image: PropTypes.string
}

export default Logo
