import React from 'react'

import Header from '../components/header'

import Home from '../screens/home'
import Support from '../screens/support'
import ContactAds from '../screens/contactAds'

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom'

const reload = () => window.location.reload()

const Routes = () => {
  return (
    <Router>
      <Header></Header>
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/contactAds' component={ContactAds}></Route>
        <Route path='/app-ads.txt' onEnter={reload} />
        {/* <Route
          path='/.well-known/apple-app-site-association'
          onEnter={reload}
        /> */}
        <Route exact path='/support' component={Support}></Route>
        <Route path='/support-:lang' component={Support}></Route>
        {/* <Redirect to='/' /> */}
      </Switch>
    </Router>
  )
}

export default Routes
