import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Collapsible from 'react-collapsible'

import { devices } from '../../config/devices'
const Question = props => {
  const { isOpen, setIsOpen } = useState(false)

  const { answer, question } = props
  return (
    <QuestionRow>
      <Collapsible
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        trigger={question}
        triggerStyle={{
          fontWeight: 'bold',
          backgroundColor: '#ffffff',
          display: 'flex',
          padding: 12,
          fontSize: '3vw',
          borderRadius: 12
        }}
        open={isOpen}
      >
        <QuestionContainer>
          {answer.map((text, index) => (
            <>
              {text.isLink ? (
                <StyledLink target='_blank' href={text.href}>
                  {text.paragraph}
                </StyledLink>
              ) : (
                <Paragraph key={index}>
                  <AnswerText>{text.paragraph}</AnswerText>
                </Paragraph>
              )}
            </>
          ))}
        </QuestionContainer>
      </Collapsible>
    </QuestionRow>
  )
}

const StyledLink = styled.a`
  font-size: 1vw;
  @media ${devices.mobileL} {
    font-size: 4vw;
  }
`

const AnswerText = styled.div`
  font-size: 4vw;
  @media ${devices.mobileL} {
    font-size: 4vw;
  }
  color: white;
  text-align: justify;
`

const Paragraph = styled.div`
  margin: 12px 0px;
`

const QuestionRow = styled.div`
  margin: 6px 0px;
`
const QuestionContainer = styled.div`
  margin-top: 12px;
  padding: 6px;
`

Question.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.array
}

export default Question
