const themeLight = {
  general: {
    header: '#2063ae90',
    primary: '#2063ae',
    button: '#7fbc4f',
    carousel: 'transparent',
    menu: '#ffffff'
  },
  font: {
    simple: '#ffffff',
    button: '#ffffff'
  }
}

const themeDark = {
  general: {
    header: '#2063ae'
  }
}

export { themeDark, themeLight }
