import axios from 'axios'

export const DISPATCH_CHARACTERS = 'DISPATCH_CHARACTERS'

export const dispatchCharacters = payload => ({
  type: DISPATCH_CHARACTERS,
  payload
})

export const potterCall = () => dispatch => {
  axios
    .get('http://hp-api.herokuapp.com/api/characters')
    .then(res => {
      dispatch(dispatchCharacters(res.data))
    })
    .catch(err => console.error(err))
}
