import { DISPATCH_CHARACTERS } from '../actions/potter'

const initialState = {
  character: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPATCH_CHARACTERS:
      return {
        ...state,
        character: action.payload
      }
    default:
      return state
  }
}
