import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Carousel from '../components/carousel'

import { PrimaryButton } from '../components/buttons'
import { SubTitle } from '../components/texts'
import HelmetConf from '../components/helmetConf'

import linkOpener from '../utils/linkOpener'

const Home = () => {
  const { t } = useTranslation()
  const toDownload = () => {
    linkOpener('http://onelink.to/muslimin')
  }
  return (
    <Container>
      <HelmetConf></HelmetConf>
      <ContentContainer>
        <Carousel></Carousel>
      </ContentContainer>
      <DescriptionContainer>
        <SubTitle textAlign='center'>{t('home.description')}</SubTitle>
      </DescriptionContainer>
      <ButtonContainer>
        <PrimaryButton
          onClick={toDownload}
          label={t('home.download')}
        ></PrimaryButton>
      </ButtonContainer>
    </Container>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DescriptionContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  padding-bottom: 128px;
  min-width: 320px;
`

const ContentContainer = styled.div``

export default Home
// export default Home
