import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const url = 'https://www.muslimin-app.com/'
const HelmetConf = ({ title = 'Muslimin', page = '' }) => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <link rel='canonical' href={`${url}${page}`} />
    </Helmet>
  )
}

HelmetConf.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string
}

export default HelmetConf
