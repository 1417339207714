import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Logo from '../logo'
import { useHistory } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { useWindowWidth } from '@react-hook/window-size'
import ReactCountryFlag from 'react-country-flag'

import BurgerMenu from '../burgerMenu'
import { devices } from '../../config/devices'
import logoImage from '../../assets/musliminLogo.png'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t, i18n } = useTranslation()
  const [animation, setAnimation] = useState('hidden')
  const [menuOpen, setMenuOpen] = useState(false)
  const history = useHistory()
  const windowWidth = useWindowWidth()
  let timer = useRef(null)

  const handleClickMobile = location => {
    history.push(location)
    handleClose()
  }

  const handleOpen = () => {
    clearTimeout(timer.current)
    setAnimation('visible')
    setMenuOpen(true)
  }

  const handleClose = () => {
    setAnimation('hidden')
    timer.current = setTimeout(() => {
      setMenuOpen(false)
    }, 1000)
  }

  const handleLangChange = () => {
    console.log('handleLangChange -> i18n.language', i18n.language)
    if (i18n.language.includes('en')) {
      localStorage.setItem('i18nextLng', 'fr')
      i18n.changeLanguage('fr')
      return
    }
    if (i18n.language.includes('fr')) {
      localStorage.setItem('i18nextLng', 'en')
      i18n.changeLanguage('en')
      return
    }
  }

  return (
    <Container>
      <LogoContainer onClick={() => history.push('/')}>
        <Logo image={logoImage}></Logo>
      </LogoContainer>
      <MenuContainer>
        <MenuButton onClick={() => history.push(`/`)}>
          <MenuText>{t('menu.home')}</MenuText>
        </MenuButton>
        <MenuButton onClick={() => history.push(`support`)}>
          <MenuText>{t('menu.support')}</MenuText>
        </MenuButton>
        <FlagContainer onClick={handleLangChange}>
          <ReactCountryFlag
            countryCode={i18n.language === 'en' ? 'GB' : 'FR'}
            svg
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '30px'
            }}
            title={i18n.language === 'en' ? 'GB' : 'FR'}
          />
        </FlagContainer>
        <MenuBurger onClick={handleOpen}>
          <FaBars size={30} color='white'></FaBars>
        </MenuBurger>
        <BurgerMenu
          windowWidth={windowWidth}
          menuOpen={menuOpen}
          animation={animation}
          handleClickMobile={handleClickMobile}
          handleClose={handleClose}
          handleLangChange={handleLangChange}
        ></BurgerMenu>
      </MenuContainer>
    </Container>
  )
}

const MenuBurger = styled.div`
  display: none;
  @media ${devices.mobileL} {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 60px;
    height: 60px;
    z-index: 5;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
const MenuText = styled.span`
  color: ${props => props.theme.general.menu};
`
const MenuButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  padding: 12px;
  cursor: pointer;
  border: none;
  @media ${devices.mobileL} {
    display: none;
  }
`

const FlagContainer = styled.div`
  cursor: pointer;
  background-color: white;
  padding: 3px;
  border-radius: 30px;
  @media ${devices.mobileL} {
    display: none;
  }
`

const LogoContainer = styled.div`
  cursor: pointer;

  display: flex;
  flex: 1;
  padding-left: 12px;
`
const MenuContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 12px;
  flex: 3;
`

const Container = styled.div`
  background-color: ${props => props.theme.general.header};
  display: flex;
  align-items: center;
  min-width: 320px;
`

export default Header
