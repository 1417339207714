import React, { useEffect } from 'react'
import styled from 'styled-components'
import Header from '../components/header'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { devices } from '../config/devices'

import {
  FaSnapchatGhost,
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaRegEnvelopeOpen
} from 'react-icons/fa'

const ContactAds = () => {
  const { t, i18n } = useTranslation()
  let { lang } = useParams()
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])
  return (
    <>
      <Header></Header>
      <ContactContainer>
        <Title>
          <CommonText>
            Vous souhaitez ajouter votre publicité sur l'application Muslimin ?
          </CommonText>
        </Title>
        <SubTitle>
          <CommonText>Contactez-nous par mail :</CommonText>
        </SubTitle>
        <ButtonContainer initial='initial' animate='end' variants={variants}>
          <a
            href='mailto:contact@musliminapp.com?subject=Publicité sur Muslimin'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ButtonEmail>
              <FaRegEnvelopeOpen color='white'></FaRegEnvelopeOpen>
            </ButtonEmail>
          </a>
        </ButtonContainer>
        <SubTitle>
          <CommonText>Ou sur nos réseaux :</CommonText>
        </SubTitle>
        <ButtonContainer initial='initial' animate='end' variants={variants}>
          <a
            href='https://www.snapchat.com/add/musliminapp/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ButtonSnap>
              <FaSnapchatGhost color='white'></FaSnapchatGhost>
            </ButtonSnap>
          </a>
          <a
            href='https://twitter.com/musliminapp'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ButtonTwitter>
              <FaTwitter color='white'></FaTwitter>
            </ButtonTwitter>
          </a>
          <a
            href='https://www.facebook.com/musliminapp/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ButtonFacebook>
              <FaFacebookF color='white'></FaFacebookF>
            </ButtonFacebook>
          </a>
          <a
            href='https://www.instagram.com/musliminapp/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ButtonInsta>
              <FaInstagram color='white'></FaInstagram>
            </ButtonInsta>
          </a>
        </ButtonContainer>
      </ContactContainer>
    </>
  )
}

const Button = styled.div`
  cursor: pointer;
  padding: 12px;
  border-radius: 24px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonInsta = styled(Button)`
  background-color: #d12798;
`

const ButtonContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const variants = {
  end: { y: 0, opacity: 1, transition: { duration: 2 } },
  initial: { y: 30, opacity: 0, transition: { duration: 2 } }
}

const ButtonSnap = styled(Button)`
  background-color: #e8e615;
`

const ButtonFacebook = styled(Button)`
  background-color: #4267b2;
`

const ButtonTwitter = styled(Button)`
  background-color: #1ca1f2;
`

const ButtonEmail = styled(Button)`
  background-color: #ce4b3b;
`

const CommonText = styled.span`
  text-align: center;
  color: white;
  font-size: 2vw;
  @media ${devices.mobileL} {
    font-size: 7vw;
  }
`

const Title = styled.div`
  display: flex;
  margin: 24px 0px 12px 0px;
`
const SubTitle = styled.div`
  display: flex;
  margin: 12px 0px 24px 0px;
`
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`

export default ContactAds
// export default Home
